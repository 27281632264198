<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<template>
  <div>
    <hb-modal
      size="large"
      :title="'Add Features and Amenities'"
      v-model="dialog"
      @close="onCloseDialog"
      :footerCancelOption="true"
      show-help-link
    >
      <!-- Spinning Loader -->
      <template v-slot:content v-if="loading">
        <div class="text-center px-10 py-10">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
      <!-- Main content -->
      <template v-slot:content v-else>
        <hb-modal
          v-model="showConfirmationDialog"
          size="medium"
          title="Discard Setup"
          confirmation
          show-help-link
        >
          <!-- Confirmation Modal -->
          <template v-slot:content>
            <div class="px-6 py-4">
              Are you sure you want to discard this setup?
            </div>
            <div class="px-6 pb-4">
              If you do, you will lose all the information you’ve entered.
              Continue the setup process to ensure Hummingbird saves your
              information.
            </div>
          </template>
          <template v-slot:right-actions>
            <hb-btn color="primary" @click="onCloseConfirmDialog">Discard</hb-btn>
          </template>
        </hb-modal>
        <!-- Tabs -->
        <hb-stepper v-model="stepper">
          <hb-stepper-header>
            <hb-stepper-step
              @click="goToStep(1)"
              :complete="steps[0].status == 'step-complete'"
              :rules="[() => steps[0].status !== 'step-error']"
              key="1-step"
              step="1"
            >
              Define Values
            </hb-stepper-step>
            
            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(2)"
              :complete="steps[1].status == 'step-complete'"
              :rules="[() => steps[1].status !== 'step-error']"
              key="2-step"
              step="2"
            >
              Apply Defaults
            </hb-stepper-step>
          </hb-stepper-header>

          <v-stepper-items>
            <template>
              <div class="px-4 py-4 hb-text-night-light">
                Select the values you want to add to the selected space type(s). Then,
                apply the defaults values for the selected property.
              </div>
            </template>
            <v-divider></v-divider>

            <v-stepper-content class="pa-0" key="1-content" step="1">
              <DefineValues
                :amenity-list="amenityList"
                ref="DefineValues"
              ></DefineValues>
            </v-stepper-content>
            <v-stepper-content class="pa-0" key="2-content" step="2">
              <ApplyDefaults
                v-if="stepper == 2"
                ref="DefaultValues"
                :amenity-list="amenityList"
                style="min-height:350px"
              ></ApplyDefaults>
            </v-stepper-content>
          </v-stepper-items>
        </hb-stepper>
      </template>
      <template v-slot:left-actions>
        <a class="hb-link ml-1" @click="onClickCreateCustomFeatureAndAmenity">
          Create Custom Feature and Amenity
        </a>
      </template>
      <template v-slot:right-actions>
        <span class="">
          <hb-btn
            v-if="stepper === 2"
            class="mr-1"
            color="secondary"
            @click="back"
            >Back</hb-btn
          >
          <hb-btn color="primary" type="submit" @click="next" :disabled="isLoading('save_amenities')">
            {{ stepper === 2 ? "Add Features and Amenities" : "Next" }}
          </hb-btn>
        </span>
      </template>
    </hb-modal>

    <hb-modal
      confirmation
      v-if="showConfirmDialog"
      v-model="showConfirmDialog"
      size="medium"
      title="Confirm Changes"
      @close="showConfirmDialog = false"
      show-help-link
    >
      <template v-slot:content>
        <div class="pa-6">
          <div class="pa-3" v-if="modifiedAmenity.addedAmenitiesCount">
            <div class="pb-2">You are about to <span class="font-weight">add</span> the following features and amenities:</div>
            <ul v-for="addedAmenity in modifiedAmenity.addedAmenities" :key="addedAmenity">
              <li>{{addedAmenity}}</li>
            </ul>
          </div>

          <div class="pa-3" v-if="modifiedAmenity.deletedAmenitiesCount">
            <div class="pb-2">You are about to <span class="font-weight">remove</span> the following features and amenities:</div>
            <ul v-for="deletedAmenity in modifiedAmenity.deletedAmenities" :key="deletedAmenity">
              <li>{{deletedAmenity}}</li>
            </ul>
          </div>

          <div class="pa-3" v-if="modifiedAmenity.addedAmenitiesCount || modifiedAmenity.deletedAmenitiesCount">
            Are you sure you want to make these changes?
          </div>

        </div>
      </template>
      <template v-slot:right-actions>
        <span class="">
          <hb-btn color="primary" type="submit" :disabled="isLoading('save_amenities')" :loading="isLoading('save_amenities')" @click="saveAmenities">
            Confirm
          </hb-btn>        
        </span>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefineValues from "./DefineValues.vue";
import ApplyDefaults from "./ApplyDefaults.vue";
import { EventBus } from '../../../../EventBus.js';
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
  name: "AddFeaturesAndAmenities",
  components: {
    DefineValues,
    ApplyDefaults
  },
  mixins: [notificationMixin],
  computed: {
    ...mapGetters({
      allFeaturesList: "featureAndAmenitiesStore/allFeaturesList"
    }),
    dialog: {
      get() {
        return this.value;
      },
      set() {}
    },
    getModifiedAmenitiesList() {
      const newAmenities = [];
      const amenities = [];
      this.countAmenitiesAdded = 0;
      for (let category in this.$refs.DefaultValues.allAmenities) {
        this.$refs.DefaultValues.allAmenities[category].forEach(
          defaultValueAmenity => {
            this.amenityList
              .find(item => item.category === category)
              .amenities.forEach(amenity => {
                if (amenity.amenity_id == defaultValueAmenity.amenity_id) {
                  if (amenity.checked) {
                    if (amenity.property_id) {
                      amenities.push(defaultValueAmenity)
                    } else {
                      newAmenities.push({ ...defaultValueAmenity, property_id: this.property_id })
                    }
                    this.countAmenitiesAdded = this.countAmenitiesAdded + 1;
                  } else if (amenity.property_id) {
                    amenities.push(amenity)
                  }
                }
              });

          }
        );
      }
      return [ ...amenities.sort((a, b) => a.property_sort_order && b.property_sort_order ? (a.property_sort_order - b.property_sort_order) : -1), ...newAmenities ]
    },
  },
  watch: {
    allFeaturesList() {
      this.loading = this.allFeaturesList.length >= 0 ? false : true;
      this.amenityList = JSON.parse(JSON.stringify(this.allFeaturesList));
      this.cacheAmenities = JSON.parse(JSON.stringify(this.allFeaturesList));

      if(this.allFeaturesList.length == 0 ) this.onClickCreateCustomFeatureAndAmenity();
    },
  },
  props: {
    value: {
      type: Boolean
    },
    property_id: {
      type: String
    },
    activeTab: {
      type: Object
    }
  },
  data() {
    return {
      countAmenitiesAdded: 0,
      amenityList: [],
      loading: false,
      tabOptions: [
        { label: "Define Values", key: 1 },
        { label: "Apply Defaults", key: 2 }
      ],
      stepper: 1,
      maxActiveStep: 1,
      showConfirmationDialog: false,
      steps: [
        { number: 1, status: "step-active" },
        { number: 2, status: "step-disabled" }
      ],
      showCustomAmenityDialog: false,
      showConfirmDialog: false,
      cacheAmenities: [],
      modifiedAmenity: {
        addedAmenities: [],
        addedAmenitiesCount: 0,
        deletedAmenities: [],
        deletedAmenitiesCount: 0,
      },
    };
  },
  created() {
    this.loading = true;
    this.getData();
  },
  destroyed() {},
  methods: {
    ...mapActions({
      fetchFeatures: "featureAndAmenitiesStore/fetchFeatures",
      saveFeatures: "featureAndAmenitiesStore/saveFeatures"
    }),
    getData() {
      this.amenityList = JSON.parse(JSON.stringify(this.allFeaturesList));
    },
    async goToStep(stepNumber, previousStepCompleted) {
      const maxStep = 3;

      // Button click on last step
      if (stepNumber > maxStep) {
        return;
      }

      // User clicks on next step button (not on stepper)
      if (previousStepCompleted) {
        this.steps[stepNumber - 1].status = "step-active";
        this.steps[stepNumber - 2].status = "step-complete";
        this.stepper = stepNumber;
        this.maxActiveStep = stepNumber;
      } else {
        if (stepNumber === 1 || stepNumber <= this.maxActiveStep) {
          this.stepper = stepNumber;
          this.steps[stepNumber - 1].status = "step-active";

          for (let i = stepNumber + 1; i <= this.maxActiveStep; i++) {
            this.steps[i - 1].status = "step-editable";
          }

          for (let j = stepNumber - 1; j > 0; j--) {
            this.steps[j - 1].status = "step-complete";
          }
        }
      }
    },

    next() {
      if (this.stepper == 1) {
        this.validateDefineValueForm();
      } else {
        this.validateApplyDefaultForm();
      }
    },

    async validateDefineValueForm() {
      let defineValuesFormValidator = await this.$refs.DefineValues.validateForm();
      if (defineValuesFormValidator) {
        this.goToStep(this.stepper + 1, true);
      } else {
        let desc = 'There are errors in your form, correct them before continuing.';
        this.showMessageNotification({type: 'error', description: desc, list: ['Please select at least one amenity.']});
        // on warning message, cancel the changes and keep original values.
        this.getData();
      }
    },

    validateApplyDefaultForm() {
      this.countAmenitiesAdded = 0;
      this.modifiedAmenity.updatedAmenitiesCount = 0;
      this.modifiedAmenity.updatedAmenities = [];
      // Adds property_id to payload
      this.$refs.DefaultValues.$validator.validateAll().then(status => {
        const errorMsg = []
        if (!status) {
          const categories = []
          for (let category in this.$refs.DefaultValues.amentiyCategoryMap) {
            this.$refs.DefaultValues.amentiyCategoryMap[category].forEach(
              defaultValueAmenity => {
                this.amenityList
                  .find(item => item.category === category)
                  .amenities.forEach(amenity => {
                    if (amenity.amenity_id == defaultValueAmenity.amenity_id && !defaultValueAmenity.property_default_value) {
                      if (!categories.includes(category)) {
                        categories.push(category)
                        errorMsg.push(`${category} is missing values for selected options.`)
                      }
                    }
                  });
              }
            );
          }
          this.showMessageNotification({type: 'error', description: 'There are errors in your form, correct them before continuing.', list: errorMsg});
        } else {
          this.getModifiedAmenitiesAndCount();
          if (this.modifiedAmenity.addedAmenitiesCount || this.modifiedAmenity.deletedAmenitiesCount) {
            this.showConfirmDialog = true;
          } else {
            this.saveAmenities();
          } 
        }
      });
    },

    saveAmenities() {
      this.startLoading('save_amenities');
      const amenities = this.getModifiedAmenitiesList;
      this.saveFeatures({ data: JSON.parse(JSON.stringify(amenities)), spaceType: this.activeTab.unit_type_name,unit_type_id:this.activeTab.unit_type_id })
        .then(r => {
          this.stopLoading('save_amenities');
          // Close popup
          this.$emit("close");
        })
        .catch(function(err) {
          throw err;
        });
    },

    back() {
      this.goToStep(this.stepper - 1, false);
    },

    onCloseDialog() {
      if (this.stepper == 1) {
        this.$emit("close");
      } else {
        this.showConfirmationDialog = true;
      }
    },

    onCloseConfirmDialog() {
      this.$emit("close");
      if (this.showCustomAmenityDialog) {
        EventBus.$emit('createCustomFeatureAndAmenityEvent');
      }
    },

    onClickCreateCustomFeatureAndAmenity() {
      // Set custom dialog
      this.showCustomAmenityDialog = true;
      if (this.stepper == 2) {
        // Confirmation Dialog
        this.showConfirmationDialog = true;
      } else {
        this.$emit("close");
        EventBus.$emit('createCustomFeatureAndAmenityEvent');
      }
    },

    getModifiedAmenitiesAndCount() {
      // Initilize
      this.modifiedAmenity.addedAmenitiesCount = 0;
      this.modifiedAmenity.deletedAmenitiesCount = 0;
      this.modifiedAmenity.addedAmenities = [];
      this.modifiedAmenity.deletedAmenities = [];

      this.modifiedAmenity.addedAmenitiesCount = this.$refs.DefaultValues.addedAmenties.length;
      this.modifiedAmenity.addedAmenities = this.$refs.DefaultValues.addedAmenties;
      this.modifiedAmenity.deletedAmenitiesCount = this.$refs.DefaultValues.deletedAmenties.length;
      this.modifiedAmenity.deletedAmenities = this.$refs.DefaultValues.deletedAmenties;
    },
  },

};
</script>

<style lang="scss" scoped>
.font-weight {
  font-weight: 500;
}
</style>
